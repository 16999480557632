<template>
  <div class="content" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>应试管理</BreadcrumbItem>
      <BreadcrumbItem>应试者管理</BreadcrumbItem>
    </Breadcrumb>
    <div class="bg-shadow func-bar">
      <div class="button-container">
        <Button
          class="filter-btn btn-shadow"
          type="primary"
          @click="
            isEdit = false
            addCandidate = true
          "
        >
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xinzeng"></use>
          </svg>
          添加
        </Button>
        <Button class="filter-btn btn-shadow" type="primary" @click="batchAdd" style="margin-left: 16px;">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-batchAdd"></use>
          </svg>
          批量添加
        </Button>
      </div>
      <div class="search-container">
        <Input class="search" v-model="searchForm.name" search placeholder="请输入姓名" @on-search="advSearch" />
        <div class="advanced-container" @click="isSpread = !isSpread">
          <span>高级搜索</span>
          <svg class="icon" :class="{ spread: isSpread }" aria-hidden="true">
            <use xlink:href="#icon-bottom"></use>
          </svg>
        </div>
        <div @mouseleave="isSpread = false" class="advanced-board bg-shadow" :class="{ show: isSpread, unshow: !isSpread }">
          <Form class="form" :model="searchForm" :label-width="60">
            <FormItem label="姓名">
              <Input v-model="searchForm.name" />
            </FormItem>
            <FormItem label="部门">
              <Select placeholder="请选择部门" :value="searchForm.departmentId">
                <Option
                  style="display: none"
                  v-for="department in departmentList"
                  :key="'dp' + department.departmentId"
                  :value="department.departmentId"
                  :label="department.name"
                ></Option>
                <Tree
                  ref="searchDepartmentTree"
                  class="tree-select"
                  v-model="searchForm.departmentId"
                  :data="departmentTreeData(false)"
                  @on-select-change="searchSelect"
                />
              </Select>
            </FormItem>
            <FormItem label="时间区间">
              <Row>
                <Col span="11">
                  <DatePicker
                    v-model="dateRange"
                    format="yyyy/MM/dd"
                    type="daterange"
                    placement="bottom-end"
                    placeholder="请选择日期"
                    style="width: 220px"
                  ></DatePicker>
                </Col>
              </Row>
            </FormItem>
            <FormItem label="是否通过">
              <RadioGroup v-model="searchForm.isPass">
                <Radio label="1">通过</Radio>
                <Radio label="0">未通过</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="状态">
              <RadioGroup v-model="searchForm.status">
                <Radio label="">已测试</Radio>
                <Radio label="1">未测试</Radio>
              </RadioGroup>
            </FormItem>
          </Form>
          <div style="display: flex">
            <Button class="filter-btn search btn-shadow" type="primary" @click="reset">重置</Button>
            <Button class="filter-btn search btn-shadow" type="primary" @click="advSearch">搜索</Button>
          </div>
        </div>
      </div>
    </div>
    <div class="table-container bg-shadow">
      <Table class="flex-table" stripe :columns="columns" :data="tableData" :loading="loading" @on-row-click="toExamList">
        <template slot-scope="{ row, index }" slot="name" style="height: 100%;">
          <span>{{ row.name }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="phone">
          <span>{{ row.phone }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="email">
          <span>{{ row.email }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="department">
          <span>{{ row.departmentName }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="createdTime">
          <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="code">
          <div v-if="!row.codeDisabled">
            <span @click.stop="copy(row.candidateCode)">{{ row.candidateCode }}</span>
          </div>
          <div v-else>
            <span style="text-shadow: 0 0 3px #000;color: rgba(255,255,255,0)" @click.stop="copy(row.candidateCode)">{{ row.candidateCode }}</span>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="pass">
          <div @click.stop="mark(row.candidateId, row.pass)">
            <Badge status="success" v-if="row.pass" />
            <Badge status="default" v-else style="cursor: pointer" />
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="operation">
          <div style="height: 100%;">
            <Tooltip content="添加考试/评测" placement="top">
              <svg
                class="icon operaIcon"
                aria-hidden="true"
                style="cursor: pointer"
                @click.stop="
                  candidateId = row.candidateId
                  addExam = true
                "
              >
                <use xlink:href="#icon-xinzeng"></use>
              </svg>
            </Tooltip>
            <Tooltip content="生成链接" placement="top" style="margin-left: 10px">
              <svg
                class="icon operaIcon"
                aria-hidden="true"
                style="cursor: pointer"
                @click.stop="copyToClipBoard(row.candidateCode, row.candidateId)"
              >
                <use xlink:href="#icon-zhuanchu"></use>
              </svg>
            </Tooltip>
            <Tooltip :content="row.codeDisabled ? '刷新应试编码' : '修改应试编码'" placement="top" style="margin-left: 10px">
              <svg
                class="icon operaIcon"
                aria-hidden="true"
                style="cursor: pointer"
                @click.stop="
                  candidateId = row.candidateId
                  codeModal = true
                "
                v-if="!row.codeDisabled"
              >
                <use xlink:href="#icon-bianji5"></use>
              </svg>
              <svg class="icon operaIcon" aria-hidden="true" style="cursor: pointer" @click.stop="refreshCode(row.candidateId)" v-else>
                <use xlink:href="#icon-shuaxin1"></use>
              </svg>
            </Tooltip>
            <Tooltip content="编辑" placement="top" style="margin-left: 10px">
              <svg class="icon operaIcon" aria-hidden="true" style="cursor: pointer" @click.stop="edit(row)">
                <use xlink:href="#icon-bianji"></use>
              </svg>
            </Tooltip>
            <Tooltip content="删除" placement="top" style="margin-left: 10px">
              <svg class="icon operaIcon" aria-hidden="true" style="cursor: pointer" @click.stop="delCandidate(row.candidateId)">
                <use xlink:href="#icon-shanchu"></use>
              </svg>
            </Tooltip>
          </div>
        </template>
      </Table>
      <div class="page">
        <div style="float: right;">
          <Page
            show-total
            show-sizer
            show-elevator
            :total="total"
            :current="page + 1"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
          />
        </div>
      </div>
    </div>
    <Modal v-model="addCandidate" :title="isEdit ? '编辑应试人' : '添加应试人'" @on-cancel="close">
      <Form ref="candidateForm" :model="candidateForm" :rules="ruleValidate" :label-width="80">
        <FormItem label="姓名" prop="name">
          <Input v-model="candidateForm.name" placeholder="请输入姓名"></Input>
        </FormItem>
        <FormItem label="手机号" prop="phone">
          <Input v-model="candidateForm.phone" placeholder="请输入手机号"></Input>
        </FormItem>
        <FormItem label="邮箱" prop="email">
          <Input v-model="candidateForm.email" placeholder="请输入邮箱"></Input>
        </FormItem>
        <FormItem label="管理部门" prop="departmentId">
          <Select placeholder="请选择部门" :value="candidateForm.departmentId">
            <Option
              style="display: none"
              v-for="department in departmentList"
              :key="'dp' + department.departmentId"
              :value="department.departmentId"
              :label="department.name"
            ></Option>
            <Tree
              ref="departmentTree"
              class="tree-select"
              v-model="candidateForm.departmentId"
              :data="departmentTreeData(false)"
              @on-select-change="onSelect"
            />
          </Select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" class="modal-btn">取消</Button>
        <Button type="primary" class="modal-btn" @click="confirmAddCandidate" :loading="addLoading">确定</Button>
      </div>
    </Modal>
    <Modal v-model="addExam" title="添加考试/评测" @on-cancel="close" width="790" class="examModal">
      <Form ref="examForm" :rules="ruleValidate" :label-width="80" :model="form">
        <Row v-for="(item, index) in form.examForm" :key="index">
          <Col span="8">
            <FormItem :label="index + 1 + ''" :prop="'examForm.' + index + '.examId'" :rules="ruleValidate.examId">
              <Cascader :data="allList" v-model="item.examId" trigger="hover" filterable></Cascader>
            </FormItem>
          </Col>
          <Col span="12" style="margin-left: 15px">
            <FormItem label="失效时间" :prop="'examForm.' + index + '.expiresTime'" :rules="ruleValidate.expiresTime">
              <DateTimePicker v-model="item.expiresTime"></DateTimePicker>
            </FormItem>
          </Col>
          <Col span="2" style="margin-left: 15px">
            <Button @click="form.examForm.splice(index, 1)">删除</Button>
          </Col>
        </Row>
        <FormItem>
          <Row>
            <Col span="5">
              <Button type="dashed" long @click="handleAdd" icon="md-add">新增</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" class="modal-btn">取消</Button>
        <Button type="primary" class="modal-btn" @click="confirmAddExam" :loading="addLoading">确定</Button>
      </div>
    </Modal>
    <Modal
      v-model="codeModal"
      title="修改应试编码"
      @on-cancel="
        $refs['code'].resetFields()
        code.candidateCode = ''
        codeModal = false
      "
    >
      <Form ref="code" :rules="ruleValidate" :label-width="80" :model="code">
        <FormItem label="应试编码" prop="candidateCode">
          <Input v-model="code.candidateCode" placeholder="请输入应试编码"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button
          @click="
            $refs['code'].resetFields()
            code.candidateCode = ''
            codeModal = false
          "
          class="modal-btn"
          >取消</Button
        >
        <Button type="primary" class="modal-btn" @click="editCode" :loading="addLoading">确定</Button>
      </div>
    </Modal>
    <Modal v-model="addCandidateConfirm" @on-ok="addExam = true">
      <div style="font-weight: bold;font-size: 1.9em;">
        <i class="ivu-icon ivu-icon-ios-help-circle" style="color: #ff9900;font-size: 30px"></i>
        提示
      </div>
      <div style="margin-top: 10px;text-align: center">
        <p style="font-size: 1.5em">是否立即添加考试/评测？</p>
        <p style="color: grey;margin-top: 10px;">({{ time }}s)</p>
      </div>
    </Modal>
    <div v-if="openBatchModal">
      <batchAddModal
        :openBatchModal="openBatchModal"
        :title="title"
        :openTimeOut="openTimeOut"
        :timeOut="timeOut"
        :IsExamOrCandidate="true"
        @on-ok="addSuccess()"
        @on-cancel="closeModal()"
      ></batchAddModal>
    </div>
  </div>
</template>

<script>
import exam from '@api/exam'
import departmentMixin from '@components/mixins/departmentMixin'
import DateTimePicker from '@components/common/DateTimePicker'
import batchAddModal from '@components/common/batchAddModal'
import timeOutMixin from '@components/mixins/candidateTimeOutJS'
export default {
  name: 'CandidateManage',
  components: { DateTimePicker, batchAddModal },
  mixins: [departmentMixin, timeOutMixin],
  data() {
    return {
      timeOut: 5,
      openTimeOut: false,
      addCandidateConfirm: false,
      addCandidate: false,
      isSpread: false,
      isEdit: false,
      total: 0,
      searchForm: {
        name: '',
        departmentId: '',
        earliestDeadline: '',
        isPass: '',
        oldestDeadline: '',
        status: ''
      },
      dateRange: ['', ''],
      loading: false,
      addLoading: false,
      addExam: false,
      examList: [],
      allList: [],
      form: {
        examForm: [
          {
            examId: [],
            expiresTime: ''
          }
        ]
      },
      candidateForm: {
        departmentId: '',
        email: '',
        name: '',
        phone: ''
      },
      ruleValidate: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        candidateCode: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('请输入应试编码'))
              } else {
                callback()
              }
            }
          }
        ],
        examId: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('请选择考试/评测'))
              } else {
                callback()
              }
            }
          }
        ],
        expiresTime: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请选择失效时间'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      columns: [
        {
          title: '姓名',
          slot: 'name',
          className: 'name-column'
        },
        {
          title: '手机号',
          slot: 'phone',
          className: 'phone-column'
        },
        {
          title: '邮箱',
          slot: 'email',
          className: 'email-column'
        },
        {
          title: '应试部门',
          slot: 'department',
          className: 'department-column'
        },
        {
          title: '添加时间',
          slot: 'createdTime',
          className: 'time-column'
        },
        {
          title: '应试编码',
          slot: 'code',
          className: 'name-column'
        },
        {
          title: '是否通过',
          slot: 'pass',
          className: 'name-column'
        },
        {
          title: '操作',
          slot: 'operation',
          className: 'operation-column'
        }
      ],
      tableData: [],
      page: 0,
      size: 10,
      candidateId: '',
      codeModal: false,
      code: {
        candidateCode: ''
      },
      isSearch: false,
      subForm: [],
      time: 3,
      Interval: '',
      openBatchModal: false,
      title: '批量导入应考人'
    }
  },
  created() {
    this.initData(0, 10)
    exam.getExam(0, 1000).then(res => {
      if (res.res.data.length > 0) {
        let arr = res.res.data.map(item => {
          item.value = item.examId
          item.label = item.name.toString()
          return item
        })
        this.allList[0] = { value: 'exam', label: '考试', children: arr }
      }
    })
    exam.getEvaluates().then(res => {
      if (res.res.data.length > 0) {
        let arr = res.res.data.map(item => {
          item.value = item.examId
          item.label = item.name.toString()
          return item
        })
        this.allList[1] = { value: 'eva', label: '评测', children: arr }
      }
    })
  },
  methods: {
    addSuccess() {
      this.openBatchModal = false
      this.openTimeOut = false
      this.initData(0, 10)
    },
    closeModal() {
      this.openBatchModal = false
      this.openTimeOut = false
      // this.clearTimeOut()
      this.getTimeOut(this.openBatchModal)
    },
    batchAdd() {
      this.openBatchModal = true
      this.openTimeOut = true
      if (this.openTimeOut) {
        // 计时器结束后不在执行
        this.timeOut = 5
        setTimeout(this.getTimeOut(this.openBatchModal), 1000)
      }
    },
    reset() {
      this.page = 0
      this.size = 0
      this.searchForm = {
        name: '',
        departmentId: '',
        earliestDeadline: '',
        isPass: '',
        oldestDeadline: '',
        status: ''
      }
      this.dateRange = ['', '']
      this.isSpread = false
      this.isSearch = false
      this.initData(0, 10)
    },
    advSearch() {
      this.isSearch = true
      this.page = 0
      this.size = 10
      this.getTime()
      this.searchData(0, 10)
      this.searchForm = {
        name: '',
        departmentId: '',
        earliestDeadline: '',
        isPass: '',
        oldestDeadline: '',
        status: ''
      }
      this.dateRange = ['', '']
    },
    searchData(page, size) {
      this.loading = true
      this.isSpread = false
      exam.searchCandidate(page, size, this.searchForm).then(res => {
        this.tableData = res.res.data
        this.total = res.res.total
        this.loading = false
      })
    },
    // getMonth(obj) {
    //   if (obj < 10) return '0' + obj
    //   else return obj
    // },
    // getDate(obj) {
    //   if (obj < 10) return '0' + obj
    //   else return obj
    // },
    getTime() {
      if (this.dateRange[0] !== '') {
        let startDay = this.dateRange[0]
        let endDay = this.dateRange[1]
        // this.searchForm.earliestDeadline =
        //   startDay.getFullYear() +
        //   '/' +
        //   this.getMonth(startDay.getMonth() + 1) +
        //   '/' +
        //   this.getDate(startDay.getDate())
        // this.searchForm.oldestDeadline =
        //   endDay.getFullYear() +
        //   '/' +
        //   this.getMonth(endDay.getMonth() + 1) +
        //   '/' +
        //   this.getDate(endDay.getDate())
        this.searchForm.earliestDeadline = startDay.valueOf()
        this.searchForm.oldestDeadline = endDay.valueOf()
      }
    },
    copyToClipBoard(code, candidateId) {
      exam.getCandidateExam(candidateId).then(res => {
        if (res.res.length > 0) {
          let clipBoardContent = `http://eva.learncoder.com/#/?code=${code}`
          this.$copyText(clipBoardContent).then(
            () => {
              this.$message.success('链接已复制到剪贴板')
            },
            () => {
              this.$message.error('复制失败')
            }
          )
        } else {
          this.$message.error('请先为应试者添加考试或评测')
        }
      })
    },
    copy(code) {
      this.$copyText(code).then(
        () => {
          this.$message.success('应试编码已复制到剪贴板')
        },
        () => {
          this.$message.error('复制失败')
        }
      )
    },
    editCode() {
      this.$refs['code'].validate(valid => {
        if (valid) {
          this.addLoading = true
          exam.editCandidateCode(this.candidateId, this.code.candidateCode).then(res => {
            if (res.res === true) {
              this.addLoading = false
              this.codeModal = false
              this.$refs['code'].resetFields()
              this.initData(this.page, this.size)
            } else {
              this.code.candidateCode = ''
              this.addLoading = false
            }
          })
        } else {
          this.$message.error('请填写必填项')
        }
      })
    },
    confirmAddExam() {
      this.subForm = JSON.stringify(this.form.examForm)
      this.$refs['examForm'].validate(valid => {
        if (valid) {
          let status = false
          const payload = JSON.parse(this.subForm).map((item, index) => {
            if (item.expiresTime < Date.now()) {
              this.$message.error(`第${index + 1}项的失效时间已过，请重新选择`)
            } else {
              status = true
              item.type = item.examId[0] === 'exam' ? 1 : 2
              item.examId = item.examId[1]
              return item
            }
          })
          if (status) {
            this.addLoading = true
            exam.addCandidateExam(this.candidateId, payload).then(res => {
              this.addLoading = false
              this.close()
            })
          }
        } else {
          this.$message.error('请填写必填项')
        }
      })
    },
    handleAdd() {
      this.form.examForm.push({
        examId: [],
        expiresTime: ''
      })
    },
    toExamList(row) {
      this.$router.push({
        path: '/candidate/candidateExam',
        query: { name: row.name, id: row.candidateId }
      })
    },
    refreshCode(id) {
      exam.refreshCandidate(id).then(res => {
        if (res.res) {
          this.initData(this.page, this.size)
        }
      })
    },
    close() {
      if (this.addExam) {
        this.$refs['examForm'].resetFields()
        this.addExam = false
        this.form.examForm = [
          {
            examId: [],
            expiresTime: ''
          }
        ]
      } else {
        this.$refs['candidateForm'].resetFields()
        this.addCandidate = false
      }
    },
    mark(id, rowPass) {
      this.$Modal.confirm({
        title: '提示',
        content: rowPass ? '<p>确认标记为不通过吗？</p>' : '<p>确认标记为通过吗？</p>',
        loading: true,
        onOk: () => {
          if (rowPass) {
            exam.unpassCandidate(id).then(res => {
              if (res.res) {
                this.$Modal.remove()
                this.initData(this.page, this.size)
              }
            })
          } else {
            exam.passCandidate(id).then(res => {
              if (res.res) {
                this.$Modal.remove()
                this.initData(this.page, this.size)
              }
            })
          }
        }
      })
    },
    edit(row) {
      this.isEdit = true
      this.candidateForm.name = row.name
      this.candidateForm.phone = row.phone
      this.candidateForm.email = row.email
      this.candidateForm.departmentId = row.departmentId
      this.candidateId = row.candidateId
      this.addCandidate = true
    },
    confirmAddCandidate() {
      this.$refs['candidateForm'].validate(valid => {
        if (valid) {
          this.addLoading = true
          if (this.isEdit) {
            exam.editCandidate(this.candidateId, this.candidateForm).then(res => {
              this.$message.success('编辑成功')
              this.initData(this.page, this.size)
              this.addLoading = false
              this.addCandidate = false
            })
          } else {
            exam.addCandidate(this.candidateForm).then(res => {
              this.time = 3
              this.Interval = setInterval(() => {
                this.time--
                if (this.time <= 0) {
                  this.addCandidateConfirm = false
                  clearInterval(this.Interval)
                }
              }, 1000)
              this.addCandidateConfirm = true
              this.candidateId = res.res
              this.initData(this.page, this.size)
              this.addLoading = false
              this.addCandidate = false
              this.$refs['candidateForm'].resetFields()
            })
          }
        } else {
          this.$message.error('请填写必填项')
        }
      })
    },
    onSelect(current) {
      this.$refs.departmentTree.dispatch('iSelect', 'on-select-selected', {
        value: current[0].detail.departmentId,
        label: current[0].detail.name
      })
      this.candidateForm.departmentId = current[0].detail.departmentId
    },
    searchSelect(current) {
      this.$refs.searchDepartmentTree.dispatch('iSelect', 'on-select-selected', {
        value: current[0].detail.departmentId,
        label: current[0].detail.name
      })
      this.searchForm.departmentId = current[0].detail.departmentId
    },
    initData(page, size) {
      this.loading = true
      exam.getCandidate(page, size).then(res => {
        this.tableData = res.res.data
        this.total = res.res.total
        this.loading = false
      })
    },
    changePage(val) {
      this.page = val - 1
      if (this.isSearch) {
        this.searchData(this.page, this.size)
      } else {
        this.initData(this.page, this.size)
      }
    },
    changePageSize(val) {
      this.size = val
      if (this.isSearch) {
        this.searchData(this.page, this.size)
      } else {
        this.initData(this.page, this.size)
      }
    },
    delCandidate(id) {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除应试人吗？</p>',
        loading: true,
        onOk: () => {
          exam.delCandidate(id).then(res => {
            if (res.res) {
              this.$Modal.remove()
              this.initData(this.page, this.size)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../theme/variables';

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.func-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 55px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 15px;

  .filter-btn {
    margin-right: 20px;
    min-width: 100px;
  }

  .search-container {
    display: flex;
    align-items: center;
    position: relative;

    .search ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;

      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }

    .advanced-container {
      flex: 1 0 auto;
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
      padding-right: 20px;

      svg.icon {
        transition: all @default-transition;
        transform: rotate(0deg);
        margin-left: 8px;
      }

      svg.icon.spread {
        transform: rotate(180deg);
      }
    }

    .advanced-board {
      position: absolute;
      z-index: 1;
      top: 56px;
      right: 0;
      width: 320px;
      height: 0;
      border-right: solid 5px @primary-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transition: all @default-transition;

      &.show {
        height: 365px;
      }

      &.unshow {
        height: 365px;
        display: none;
      }
      .search {
        margin-right: 20px;
        height: 40px;
        border-radius: 4px;
      }

      .form {
        width: 280px;
        margin-top: 20px;
      }
    }
  }
}

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .status-container {
      display: flex;
      align-items: center;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .operation-tooltip {
      margin-right: 10px;
      cursor: pointer;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }
    .name-column {
      width: 10%;
    }

    .time-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .department-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .email-column {
      width: 15%;
    }

    .phone-column {
      width: 15%;
    }

    .operation-column {
      width: 15%;
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 20px;
    font-size: 14px;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
::v-deep .ivu-badge-status-dot {
  width: 15px;
  height: 15px;
}
::v-deep .ivu-table-row {
  cursor: pointer;
}
.examModal ::v-deep .ivu-modal-body {
  height: calc(100vh - 200px);
  overflow: auto;
}
::v-deep .flex-table.ivu-table-wrapper .ivu-table .ivu-table-body {
  overflow: hidden;
}
</style>
