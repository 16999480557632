import { mapGetters } from 'vuex'

export default {
  created: function() {
    if (this.departmentTree === null) {
      this.$Loading.start()
      this.$store.dispatch('department/getDepartmentTree').finally(() => {
        this.$Loading.finish()
      })
    }
  },
  computed: {
    ...mapGetters({
      departmentTree: 'department/departmentTree',
      departmentTreeData: 'department/departmentTreeData',
      departmentList: 'department/departmentList'
    })
  }
}
