export default {
  data() {
    return {
      openBatchModal: false,
      timeOut: 5,
      openTimeOut: false
    }
  },
  methods: {
    getTimeOut(openBatchModal) {
      if (openBatchModal === false || this.timeOut === 0) {
        this.timeOut = 0
        this.openTimeOut = false
        clearTimeout(this.getTimeOut)
      }
      if (this.timeOut > 0) {
        setTimeout(this.getTimeOut, 1000)
        this.timeOut--
      }
    }
  }
}
